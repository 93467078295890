import { PaymentInquiryFilter } from "../modules/app/models/interfaces/PaymentInquiryDetail";
import { endPoints } from "./endpoints";
import instance from "./service";

export const getPaymentInquiryDetail = (
  payload: PaymentInquiryFilter
): Promise<any> => {
  return instance.post(
    endPoints.PAYMENT_INQUIRY_DETAIL_URL,
    payload
  );
};

// export const getRevertedTransactionsList = (
//     payload: RevertedTransactionFilter
//   ): Promise<any> => {
//     return instance.post(
//       endPoints.REVERTED_PAYMENT_LIST_URL,
//       payload
//     );
//   };
