import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { changePassword } from '../../../../services/UserService';
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import clsx from 'clsx';
import { Button } from 'react-bootstrap';

const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: ''
};

const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .min(6, 'Minimum 6 characters')
        .max(20, 'Maximum 20 characters')
        .required('Old Password is required'),
    password: Yup.string()
        .min(6, 'Minimum 6 characters')
        .max(20, 'Maximum 20 characters')
        .required('New Password is required'),
    confirmPassword: Yup.string()
        .required('New Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        })
});

function ChangePassword() {
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    const cpForm = useFormik({
        initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true);
            setSuccessMsg('');
            try {
                const { data } = await changePassword({
                    password: values.oldPassword,
                    newPassword: values.password,
                    confirmNewPassword: values.confirmPassword,
                });
                setSuccessMsg(data.message);
                resetForm();
            } catch (error) {
                const err: any = error;
                setStatus(err.response.data.message);
                setSubmitting(false);
                setLoading(false);
            }
        },
    });
    const updateHeaderContext = useUpdateHeaderContext();
    useEffect(() => {
        updateHeaderContext({ pageTitle: 'Change Password' });
    }, []);
    return (
        <div className="dashboard-middle-content">
    <div className="card mb-5 p-4">
        <h6 className="text-center mb-4">Update Password</h6>
        <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
                <form onSubmit={cpForm.handleSubmit} noValidate id="register_form">
                    {cpForm.status && (
                        <div className="alert alert-danger mb-3">
                            <div className="alert-text font-weight-bold">{cpForm.status}</div>
                        </div>
                    )}
                    {successMsg && (
                        <div className="alert alert-success mb-3">
                            <div className="alert-text font-weight-bold">{successMsg}</div>
                        </div>
                    )}
                    <div className="form-group mb-3">
                        <label className="payzen-label mb-0">Old Password</label>
                        <input
                            className={clsx('form-control', {
                                'is-invalid': cpForm.touched.oldPassword && cpForm.errors.oldPassword,
                                'is-valid': cpForm.touched.oldPassword && !cpForm.errors.oldPassword,
                            })}
                            {...cpForm.getFieldProps('oldPassword')}
                            type="password"
                            id="oldPassword"
                            name="oldPassword"
                            autoComplete="off"
                        />
                        {cpForm.touched.oldPassword && cpForm.errors.oldPassword && (
                            <div className="invalid-feedback">{cpForm.errors.oldPassword}</div>
                        )}
                    </div>
                    <div className="form-group mb-3 ">
                        <label className="payzen-label mb-0 ">New Password</label>
                        <input
                            className={clsx('form-control', {
                                'is-invalid': cpForm.touched.password && cpForm.errors.password,
                                'is-valid': cpForm.touched.password && !cpForm.errors.password,
                            })}
                            {...cpForm.getFieldProps('password')}
                            type="password"
                            id="password"
                            name="password"
                            autoComplete="off"
                        />
                        {cpForm.touched.password && cpForm.errors.password && (
                            <div className="invalid-feedback">{cpForm.errors.password}</div>
                        )}
                    </div>
                    <div className="form-group mb-3">
                        <label className="payzen-label mb-0 ">Confirm New Password</label>
                        <input
                            className={clsx('form-control', {
                                'is-invalid': cpForm.touched.confirmPassword && cpForm.errors.confirmPassword,
                                'is-valid': cpForm.touched.confirmPassword && !cpForm.errors.confirmPassword,
                            })}
                            {...cpForm.getFieldProps('confirmPassword')}
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            autoComplete="off"
                        />
                        {cpForm.touched.confirmPassword && cpForm.errors.confirmPassword && (
                            <div className="invalid-feedback">{cpForm.errors.confirmPassword}</div>
                        )}
                    </div>
                    <div className="text-center mt-3">
                        <Button variant="primary" type="submit" disabled={loading} className="btn-block">
                            {loading ? 'Updating...' : 'Update'}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
    );
}

export default ChangePassword;
