import { useFormik } from "formik";
import { useEffect, useState, useCallback } from "react";
import * as Yup from "yup";

import {
  getInterfaces,
  getPermissions,
  getRoles,
  updateRolesStatus,
  updateRoles,
  saveRoles,
} from "./../../../services/UserService";
import clsx from "clsx";
import { useUpdateHeaderContext } from "../../../contexts/HeaderContext";
import { Modal } from "react-bootstrap";
import classes from "./user-management/styles.module.css";
import { Dropdown } from "react-bootstrap";

const permissionListStyle = {
  listStyle: "none",
  display: "inline-block",
  marginLeft: "10px",
  alignItems: "center",
  gap: "4px",
};

const submitScheme = Yup.object().shape({
  roleType: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 20 symbols")
    .required("Role Type is required"),
  displayName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(20, "Maximum 20 symbols")
    .required("Role Name is required"),
});

const initialValues = {
  roleType: "",
  displayName: "",
};

const intialFormState = {
  roleId: 0,
  isRoleUpdate: false,
  role: {},
  selectedInterfaces: [],
};

function UserRole() {
  const [interfacesList, setInterfacesList] = useState<any>([]);
  const [permissionsList, setPermissionList] = useState<any>([]);
  const [roleList, setRoleList] = useState<any>([]);
  const [selectedInterfaces, setSelectedInterfaces] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [formData, setFormData] = useState<any>(intialFormState);
  const updateHeaderContext = useUpdateHeaderContext();
  const [updateUserTitle, setUpdateUserTitle] = useState(false);

  useEffect(() => {
    getInterfacesList();
  }, []);

  useEffect(() => {
    getPermissionsList();
  }, []);

  useEffect(() => {
    getRolesList();
  }, []);

  const getInterfacesList = async () => {
    const response = await getInterfaces();
    setInterfacesList(response.data.content);
  };

  const getPermissionsList = async () => {
    const response = await getPermissions();
    var permiNew = prePareNewPermission(response.data.content);
    setPermissionList(permiNew);
  };

  const prePareNewPermission = (perList: any) => {
    var newList = [];
    for (let i = 0; i < perList.length; i++) {
      if (perList[i].active) {
        newList.push({
          id: perList[i].id,
          permissionType: perList[i].permissionType,
          displayName: perList[i].displayName,
          active: perList[i].active,
          selected: false,
        });
      }
    }
    return newList;
  };

  const getRolesList = async () => {
    setLoading(true);
    const response = await getRoles();
    setRoleList(response.data.content);
    setLoading(false);
  };

  const [isSending, setIsSending] = useState(false);
  const sendStatusRequest = useCallback(
    async (id: any, status: any) => {
      if (isSending) return;
      const postdata = {
        id: id,
        active: status,
      };
      setIsSending(true);
      await updateRolesStatus(postdata);
      setIsSending(false);
    },
    [isSending]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: submitScheme,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const postdata = {
          id: formData.roleId,
          name: values.roleType,
          displayName: values.displayName,
          departmentServices: "1",
          companyId: 0,
          rolePermissions: getListOfRolePermissions(),
        };
        if (formData.isRoleUpdate) {
          await updateRoles(postdata);
        } else {
          await saveRoles(postdata);
        }
        setSubmitting(false);
        setLoading(false);
        getInterfacesList();
        values.roleType = "";
        values.displayName = "";
        cancelForm();
      } catch (error) {
        console.error(error);
        setStatus("At least one permission should be selected");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const openRoleForm = () => {
    formik.values.displayName = "";
    const formState: any = intialFormState;
    formState.selectedInterfaces = prepareSelectedInterfaces();
    setFormData(formState);
    setTimeout(() => setShowRoleForm(true), 100);
    setUpdateUserTitle(false);
  };

  const updateValues = (row: any) => {
    const formState: any = intialFormState;
    formState.isRoleUpdate = true;
    const role: any = roleList.find((r: any) => r.id == row.id);
    formik.values.displayName = role.displayName;
    formik.values.roleType = role.roleType;
    formState.role = role;
    formState.roleId = role.id;
    formState.selectedInterfaces = prepareSelectedInterfaces(true, role);
    setFormData(formState);

    setTimeout(() => setShowRoleForm(true), 100);
    setUpdateUserTitle(true);
  };

  const cancelForm = () => {
    setFormData(intialFormState);
    setShowRoleForm(false);
    setUpdateUserTitle(false);
  };

  const updateStatus = async (id: any, status: any) => {
    const postData = {
      id: id,
      activated: status,
    };
    const { data } = await updateRolesStatus(postData);
    getRolesList();
  };

  const getListOfRolePermissions = () => {
    var listOfPermissions = [];

    for (var i = 0; i < selectedInterfaces.length; i++) {
      for (var j = 0; j < selectedInterfaces[i].permissions.length; j++) {
        var newPer = selectedInterfaces[i].permissions[j];
        if (newPer.selected) {
          listOfPermissions.push({
            interfaceId: selectedInterfaces[i].id,
            permissionId: newPer.id,
          });
        }
      }
    }
    return listOfPermissions;
  };

  const prepareSelectedInterfaces = (isUpdate = false, editRole: any = {}) => {
    let interfaces: any[] = [];
    interfacesList.forEach((it: any) => {
      let permList: any[] = [];
      permissionsList.forEach((pl: any) => {
        let perm: any = {
          active: pl.active,
          displayName: pl.displayName,
          id: pl.id,
          permissionType: pl.permissionType,
        };
        const val = isUpdate
          ? getInterfacePermissionStatus(it.id, pl.id, editRole)
          : false;
        if (val) {
          perm.selected = val;
        } else {
          perm.selected = false;
        }
        permList.push(perm);
      });
      interfaces.push({
        id: it.id,
        interfaceName: it.interfaceType,
        selected: isUpdate
          ? getInterfaceSelectedStatus(it.id, editRole)
          : false,
        permissions: permList,
      });
    });
    setSelectedInterfaces(interfaces);
    return interfaces;
  };

  const getInterfaceSelectedStatus = (interfaceId: number, editRole: any) => {
    const roleInterface = editRole.rolePermissions
      ? editRole.rolePermissions.find(
          (rp: any) => rp.interfaceId == interfaceId
        )
      : null;
    return roleInterface ? true : false;
  };

  const getInterfacePermissionStatus = (
    interfaceId: number,
    permissionId: number,
    editRole: any
  ) => {
    const roleInterface = editRole.rolePermissions
      ? editRole.rolePermissions.find(
          (rp: any) => rp.interfaceId === interfaceId
        )
      : null;
    if (roleInterface) {
      const roleInterfacePermission = roleInterface.permissions.find(
        (rip: any) => rip.id === permissionId
      );
      return roleInterfacePermission ? true : false;
    } else {
      return false;
    }
  };

  const updatePermissionCheckBox = (
    indexInt: any,
    newInterface: any,
    newPer: any,
    index: any
  ) => {
    var newPerObj = newInterface.permissions[index];
    newPerObj.selected = !newPer.selected;
    newInterface.permissions[index] = newPerObj;
    if (newPerObj.selected && !newInterface.selected) {
      newInterface.selected = true;
    }
    const currentSelectedInterfaces = selectedInterfaces;
    currentSelectedInterfaces[indexInt] = newInterface;
    setSelectedInterfaces(currentSelectedInterfaces);
  };

  var rows =
    roleList &&
    !loading &&
    roleList.length > 0 &&
    roleList.map((row: any, index: any) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          {/* <td>{row.id}</td> */}
          <td>{row.displayName}</td>
          <td>{row.roleType}</td>
          <td className="">
            {/* <button
              className={
                row.active ? "btn btn-active" : "btn btn-inactive"
              }
              onClick={async () => {
                await updateStatus(row.id, !row.active);
              }}
            >
              {row.active ? "Active" : "Inactive"}
            </button> */}
            {row.active ? "Active" : "Inactive"}
          </td>
          <td className="">
            {/* <button
              className="btn btn-edit"
              onClick={() => updateValues(row)}
            >
              Edit
            </button> */}
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                size="sm"
                className={classes.dropdownToggleAfter}
              >
                <i
                  className="bi bi-three-dots-vertical icon-red"
                  style={{ color: "green" }}
                ></i>
              </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateValues(row)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  await updateStatus(row.id, !row.active);
                }}
              >
                {row.active ? "Active" : "Inactive"}
              </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
          </td>
        </tr>
      );
    });

  var listItems = selectedInterfaces.map((newInterface: any, index: any) => (
    <li key={index}>
      {newInterface.interfaceName}
      <ul>
        {newInterface.permissions.map((perObj: any, perIndex: any) => (
          <li style={permissionListStyle} key={perObj.id}>
            <input
              type="checkbox"
              defaultChecked={perObj.selected}
              onClick={() => {
                updatePermissionCheckBox(index, newInterface, perObj, perIndex);
              }}
            />
            {perObj.displayName}
          </li>
        ))}
      </ul>
    </li>
  ));

  useEffect(() => {
    updateHeaderContext({ pageTitle: "User Roles" });
  }, []);

  return (
    <div className="dashboard-middle-content">
      <div className="col-md-12">
        <div className="white-bg">
          <div className="float-right">
            <button
              className={`mb-3 ${classes.addRoleButton}`}
              onClick={openRoleForm}
            >
              Update Role
            </button>
          </div>
          {showRoleForm && (
            <Modal
              size="lg"
              style={{ width: "1000px", margin: "auto" }}
              show={showRoleForm}
              onHide={() => setShowRoleForm(false)}
            >
              <div
                className="modal-content p-2"
                style={{ maxHeight: "90vh", overflowY: "auto" }}
              >
                <div style={{ backgroundColor: "white", padding: "30px" }}>
                <h3 className="text-center mb-5" style={{ fontWeight:'600' }}>{updateUserTitle ? "Edit Role": "Add Role"}</h3>
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id="login_form"
                  >
                    {formik.status ? (
                      <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">
                          {formik.status}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">Name</label>
                        </div>
                        <input
                          className={clsx(
                            "w-100",
                            "form-control",
                            {
                              "is-invalid":
                                formik.touched.displayName &&
                                formik.errors.displayName,
                            },
                            {
                              "is-valid":
                                formik.touched.displayName &&
                                !formik.errors.displayName,
                            }
                          )}
                          {...formik.getFieldProps("displayName")}
                          type="text"
                          id="displayName"
                          name="displayName"
                          autoComplete="off"
                        />
                        {formik.touched.displayName &&
                          formik.errors.displayName && (
                            <div className="invalid-feedback">
                              {" "}
                              {formik.errors.displayName}{" "}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">Role Type</label>
                        </div>
                        <input
                          className={clsx(
                            "w-100",
                            "form-control",
                            {
                              "is-invalid":
                                formik.touched.roleType &&
                                formik.errors.roleType,
                            },
                            {
                              "is-valid":
                                formik.touched.roleType &&
                                !formik.errors.roleType,
                            }
                          )}
                          {...formik.getFieldProps("roleType")}
                          type="text"
                          id="roleType"
                          name="roleType"
                          autoComplete="off"
                        />
                        {formik.touched.roleType && formik.errors.roleType && (
                          <div className="invalid-feedback">
                            {" "}
                            {formik.errors.roleType}{" "}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Interfaces</th>
                              <th>Permissions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.selectedInterfaces.map(
                              (interfaceObj: any, index: any) => (
                                <tr key={index}>
                                  <td>{interfaceObj.interfaceName}</td>
                                  <td>
                                    {interfaceObj.permissions.map(
                                      (perObj: any, perIndex: any) => (
                                        <li
                                          style={permissionListStyle}
                                          key={perObj.id}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "4px",
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              defaultChecked={
                                                interfaceObj.selected &&
                                                perObj.selected
                                              }
                                              onClick={() => {
                                                updatePermissionCheckBox(
                                                  index,
                                                  interfaceObj,
                                                  perObj,
                                                  perIndex
                                                );
                                              }}
                                              style={{ marginTop: "1px" }}
                                            />
                                            <div>{perObj.displayName}</div>
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-6 mb-3"></div>
                      <div className="clearfix"></div>
                      <div className="col-md-6">
                        <div className="float-right mt-4">
                          <button
                            className={classes.cancelButton}
                            type="button"
                            onClick={cancelForm}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            id="sign_in_submit"
                            className={`${classes.saveButton} ml-4`}
                            disabled={formik.isSubmitting || !formik.isValid}
                          >
                            <span className="indicator-label">Add Role</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
      {roleList && roleList.length && (
        <div
          className="mt-5"
          data-aos="fade-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          style={{ margin: "20px" }}
        >
          <table>
            <thead>
              <tr>
                <th>Sr#</th>
                {/* <th>Id</th> */}
                <th>Display Name</th>
                <th>Role Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </td>
                </tr>
              ) : (
                rows
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UserRole;
