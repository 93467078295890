import DashboardIcon from '../../../src/assets/images/Group 40013.png';
import Utilities from '../../../src/assets/images/settingsIcon.png';
import UserManagment  from '../../../src/assets/images/Group 39927.png';
import TrafficIcon from '../../../src/assets/images/Traffic.png'
import ReportIcon from  '../../../src/assets/images/ReportsIcon.png'


export function dateFormat(date: string): string {
    const objDate = new Date(date); 
    const formattedDate = objDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
}
export function numberWithCommas(x: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
type Icon = 
  | { src: string; alt: string } 
  | string;  

export const icons: Icon[]  = [
    { src: DashboardIcon, alt: "Dashboard" },  
    { src: ReportIcon , alt: "Dashboard" }, 
    { src: Utilities , alt: "Utilities" }, 
    { src: TrafficIcon , alt: "TrafficIcon" },  
    { src: UserManagment , alt: "UserManagment" },  
   
 
];
