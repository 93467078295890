import classes from "./styles.module.css";
interface DashboardBoxProps {
  title: string;
  value: string;
  iconPath: string;
  backgroundColor?: string;
}

const DashboardBox = ({
  title,
  value,
  iconPath,
  backgroundColor,
}: DashboardBoxProps) => {
  return (
    <div
      style={{
        backgroundColor: backgroundColor ?? "#ecf1ff",
      }}
      className={classes.dashboardBox1}
    >
      <div className={classes.dashboardBox2}>
        {title}
        <div style={{ color: "#232323", fontSize:'23px', fontWeight:'600' }}>{value}</div>
      </div>
      <img
        src={iconPath}
        alt="dashboard-icon"
        className="img-responsive"
        width={55}
        height={55}
      />
    </div>
  );
};

export default DashboardBox;
