import { ICompany } from "../models/interfaces";
import Select, { components } from "react-select";

interface iProps {
  companies: ICompany[];
  onChange: any;
  value: string | null;
  allCompanies?: boolean;
}
const CompanySearchDropDown = ({
  companies,
  onChange,
  value,
  allCompanies = true,
}: iProps) => {
  const ValueContainer = ({ children, ...props }: any) => (
    <components.ValueContainer {...props}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px"  }}>
        <i className="bi bi-briefcase" aria-hidden="true" />{" "}
        {children}
      </div>
    </components.ValueContainer>
  );

  const getCompanyOptions = () => {
    const mappedCompanies = companies?.map((c: ICompany) => ({
      value: c.getId(),
      label: c.getCompanyName(),
    }));
    return allCompanies
      ? [
          {
            value: "all",
            label: "All Companies",
          },
          ...(mappedCompanies || []),
        ]
      : mappedCompanies || [];
  };
  if (value === "") {
    const selectElement = document.getElementById("selectCompany");
    if (selectElement instanceof HTMLSelectElement) {
      selectElement.value = "";
    }
  }
  return (
    <Select
      options={getCompanyOptions()}
      onChange={(selectedOption: any) => {
        const companyServiceId = selectedOption ? selectedOption?.value : "0";
        onChange({ target: { value: companyServiceId } });
      }}
      name="selectCompany"
      placeholder="Select Company"
      isSearchable={true}
      components={{ ValueContainer }} 
      id="company-search"
      value={
        value
          ? getCompanyOptions().find((company) =>
              // value
                 Number(company.value) === Number(value)
                //: company.value === ""
            )
          : "Select Company"
      }
    />
  );
};

export default CompanySearchDropDown;
