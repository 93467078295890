import { ICompanyService } from "../models/interfaces";
import Select, { components } from "react-select";

interface iProps {
  companyServices: ICompanyService[];
  onChange: any;
  value: string;
}
const ServicesSearchDropDown = ({
  companyServices,
  onChange,
  value,
}: iProps) => {
  const getCompanyOptions = () => {
    const mappedCompanies = companyServices?.map((c: ICompanyService) => ({
      value: c.getId(),
      label: c.getServiceName(),
    }));

    return [
      {
        value: "",
        label: "All Services",
      },
      ...(mappedCompanies || []),
    ];
  };

  const ValueContainer = ({ children, ...props }: any) => (
    <components.ValueContainer {...props}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <i className="bi bi-grid" aria-hidden="true" />
        {children}
      </div>
    </components.ValueContainer>
  );

  return (
    <Select
      options={getCompanyOptions()}
      onChange={(selectedOption: any) => {
        const companyServiceId = selectedOption ? selectedOption?.value : "0";
        onChange({ target: { value: companyServiceId } });
      }}
      placeholder="Select Service"
      isSearchable={true}
      components={{ ValueContainer }}
      value={getCompanyOptions().find((company) =>
        value ? Number(company.value) === Number(value) : company.value === ""
      )}
    />
  );
};

export default ServicesSearchDropDown;
