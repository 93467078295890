import React, { useMemo } from "react";
import { useTable } from "react-table";
import Loader from "./loader";
import styles from "./styles.module.css";

const AppTable = ({ columns, data, isLoading }: any) => {
  const columnData: any = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columnData,
      data: rowData,
    });
  return (
    <div className={styles.scrollable}>
      {isLoading ? (
        <div
          className="p-auto w-100"
          style={{ textAlign: "center", marginTop: "100px" }}
        >
          <Loader />
        </div>
      ) : (
        <div className={`table-responsive ${styles.tableFixHead}`}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
              {rows.length === 0 && (
                <tr>
                  <td colSpan={headerGroups?.[0]?.headers?.length}>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        margin: "auto",
                      }}
                    >
                      No record found.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AppTable;
