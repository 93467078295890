import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";
import {
  ICompany,
  ICompanyService,
  IPsidFilter,
} from "../../models/interfaces";
import { dt } from "../../../../helpers/AppHelpers";
import { IPsidResponse } from "../../models/interfaces/IPsidResponse";
import {
  getCompanies,
  getCompanyServices,
  getPsidList,
} from "../../../../services/PsidService";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { useAuth } from "../../../auth/core/Auth";
import CompanySearchDropDown from "../../reusableComponents/CompanySearchDropDown";
import ServicesSearchDropDown from "../../reusableComponents/ServicesSearchDropDown";
import { searchUtility } from "../../../../utils/searchUtility";
import FilterBox from "../../reusableComponents/FilterBox";
import ConsumerNumber from "../../reusableComponents/ConsumerNumber";

function PsidList() {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);

  const COLUMNS = [
    {
      Header: "Sr#",
      accessor: "srno",
    },
    {
      Header: "Service",
      accessor: "serviceName",
    },
    // {
    //   Header: "Challan #",
    //   accessor: "challanNumber",
    // },
    
    {
      Header: "PSID",
      accessor: "consumerNumber",
    },
    {
      Header: "Consumer Name",
      accessor: "consumerName",
    },
    
    {
      Header: "Amount",
      accessor: "amountWithinDueDate",
    },
    // {
    //   Header: "Payment Status",
    //   accessor: "billStatus",
    // },
    
    {
      Header: "Created At",
      accessor: "createdAt",
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
    },
    {
      Header: "Expiry Date",
      accessor: "expiryDate",
    },
    
    {
      Header: "Expired",
      accessor: "isExpired",
    },
  ];

  const updateHeaderContext = useUpdateHeaderContext();
  const columns: any = useMemo(() => COLUMNS, []);
  const transactionType: any = useMemo(() => ["ALL", "1BILL", "CARD"], []);
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companyServices, setCompanyServices] = useState<ICompanyService[]>([]);
  const initialFilter = {
    startDate: _newDate,
    endDate: _newDate,
    companyId: "0",
    serviceId: "0",
    pageIndex: currentPage - 1,
    pageSize: 10,
    search: "",
    transactionType: transactionType[0],
    psidType:"",
  };
  const [filter, setFilter] = useState<IPsidFilter>({
    startDate: _newDate,
    endDate: _newDate,
    companyId: "0",
    serviceId: "0",
    pageIndex: currentPage - 1,
    pageSize: 10,
    search: "",
    transactionType: transactionType[0],
    psidType:"",
  });

  const [psid, setPsid] = useState<IPsidResponse[]>();

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Psid List" });
    getCompanyList();
  }, []);

  //keep an eye on table pagination. If Page size changes. function will be called
  useEffect(() => {
    // console.log('useEffect :', filter)
    getReportData();
  }, [currentPage, pageSize]);

  const showCompanyDropdown = (): boolean => {
    return [
      "ROLE_PITB_ADMIN",
      "ROLE_ADMIN",
      "PITB_FINANCE",
      "ROLE_PITB_OPERATIONS",
    ].includes(auth?.role?.roleType);
  };

  const isDepartmentAdmin = (): boolean => {
    return auth?.role?.roleType == "ROLE_DEPARTMENT_ADMIN";
  };

  const getCompanyList = async () => {
    if (isDepartmentAdmin() && auth?.companyId) {
      getCompanyServiceList(auth?.companyId);
    } else {
      const response = await getCompanies();
      setCompanies(response);
    }
  };

  const getCompanyServiceList = async (companyId: number) => {
    const response = await getCompanyServices(companyId);
    setCompanyServices(response);
  };

  const getReportData = async (init: boolean = false) => {
    setLoader(true);
    const response = await getPsidList(init ? initialFilter : filter);
    if (response.status === 200) {
      const { content, totalAmount, totalRecord } = response.data;
      const psidObj: IPsidResponse[] = content;
      setPsid(psidObj);
      // console.log(psidObj);
      setPageData((prevState: any) => ({
        ...prevState,
        isLoading: false,
        rowData: psidObj
          ? psidObj.map((p: any, i) => {
              p.srno = (currentPage - 1) * pageSize + i + 1;
              p.isExpired = p.isExpired ? "Yes" : "No";
              return p;
            })
          : [],
        totalPages: 10,
        totalRows: Number(totalRecord),
      }));
    }
    setLoader(false);
  };

  // OnChange Handler

  const onChangeStartDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const companyChangeHandler = (e: any) => {
    let companyId = "0";
    if (e.target.value) {
      companyId = e.target.value;
      getCompanyServiceList(e.target.value);
    } else {
      setCompanyServices([]);
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, companyId, serviceId: "0" }));
  };

  const serviceChangeHandler = (e: any) => {
    let serviceId = "0";
    if (e.target.value) {
      serviceId = e.target.value;
    } else {
      setCompanyServices([]);
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, serviceId }));
  };

  const statusChangeHandler = (e: any) => {
    const transactionType = e.target.value;
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, transactionType }));
  };

  function onSearchChange(value: any) {
    searchUtility(value, setFilter, companies, getCompanyServiceList);
  }

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
    setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
    setFilter((filter: any) => ({
      ...filter,
      pageSize: pageItem,
      pageIndex: 0,
    }));
  };

  function exportExcel(): void {
    if (pageData.totalRows > 0) {
      const payload = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        companyId: filter.companyId,
        serviceId: filter.serviceId,
        search: -1,
        totalItems: pageData.totalRows,
      };

      const userName: any = auth?.username;
      // console.log("Token", userName);
      window.location.assign(
        process.env.REACT_APP_API_URL +
          "/reporting/download/psids/" +
          payload.startDate +
          "/" +
          payload.endDate +
          "/" +
          payload.serviceId +
          "/" +
          payload.companyId +
          "/" +
          payload.search +
          "/" +
          payload.totalItems +
          "/" +
          btoa(userName)
      );
    }
  }

  return (
    <div className="dashboard-middle-content">
      {loader || pageData.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
      ) : (
        <>
          <FilterBox _initFilters={_initFilters} getReportData={getReportData}>
            <div className="w-100">
              <ConsumerNumber
                search={filter?.search}
                onSearchChange={onSearchChange}
              />
            </div>
            {showCompanyDropdown() && (
              <div className="w-100">
                <CompanySearchDropDown
                  companies={companies}
                  onChange={companyChangeHandler}
                  value={filter.companyId}
                />
              </div>
            )}
            <div className="w-100">
              <ServicesSearchDropDown
                companyServices={companyServices}
                onChange={serviceChangeHandler}
                value={filter.serviceId}
              />
            </div>
            <div className="form-group has-search">
              <i
                style={{ transform: "rotate(90deg)" }}
                className="fa fa-exchange form-control-feedback"
                aria-hidden="true"
              ></i>
              <select
                className="form-control"
                onChange={statusChangeHandler}
                value={filter?.transactionType}
              >
                {transactionType.map((ps: string) => (
                  <option key={ps} value={ps}>
                    {ps}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-100">
              <input
                className="dashboard-top-filter-input"
                type="date"
                value={filter?.startDate || ""}
                onChange={onChangeStartDate}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  e.currentTarget.focus();
                }}
              />
            </div>
            <div className="w-100">
              <input
                className="dashboard-top-filter-input"
                type="date"
                value={filter?.endDate || ""}
                onChange={onChangeEndDate}
              />
            </div>
          </FilterBox>
          {pageData?.rowData && (
            <>
              <div className="card">
                <AppTable
                  columns={columns}
                  data={pageData.rowData}
                  isLoading={pageData.isLoading || loader}
                />
              </div>
              <Pagination
                totalRows={pageData.totalRows}
                pageChangeHandler={setPageIndex}
                rowsPerPage={filter.pageSize}
                currentPage={currentPage}
                pageItemChangeHandler={onLoadPageItems}
              />
            </>
          )}
        </>
      )}
    </div>
  );
  
}

export default PsidList;
