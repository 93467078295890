
const ConsumerNumber = ({ search, onSearchChange }: any) => {
  const onChange = (e: any) => {
    const value = e.target.value.trim();
    if (value === "" || (/^\d+$/.test(value) && value.length <= 14)) {
      onSearchChange(value);
    }
  };
  return (
    <div className={`form-group has-search`}>
      <i className="bi bi-hash form-control-feedback"></i>
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={search}
        onChange={onChange}
        maxLength={14}
      />
    </div>
  );
};

export default ConsumerNumber;
