import React, { useEffect, useMemo, useRef, useState } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import FilterBox from "../../reusableComponents/FilterBox";
import ConsumerNumber from "../../reusableComponents/ConsumerNumber";
import moment from "moment";
import Swal from "sweetalert2";
import { dt } from "../../../../helpers/AppHelpers";
import { getPaymentInquiryDetail } from "../../../../services/PaymentInquiry";
import { PaymentInquiryFilter } from "../../models/interfaces/PaymentInquiryDetail";

function PaymentInquiry() {
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(() => moment(new Date()).format(dt.dbDateFormat), []);
  const [inquiryDetailResponses, setInquiryDetailResponses] = useState([]);
  const [paymentDetailResponses, setPaymentDetailResponses] = useState([]);
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [loader, setLoader] = useState(false);
  const initialFilter: PaymentInquiryFilter = {
    startDate: _newDate,
    endDate: _newDate,
    consumerNumber: "",
  };
  const isFirstRender = useRef(true);
  const [filter, setFilter] = useState<PaymentInquiryFilter>(initialFilter);

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Inquiry Payment Details" });
  }, []);

  const _initFilters = () => {
    setFilter(initialFilter);
    setInquiryDetailResponses([]);
    setPaymentDetailResponses([]);
    getReportData(true);
  };

  const onChangeStartDate = (e: any) => {
    setFilter((prev) => ({ ...prev, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setFilter((prev) => ({ ...prev, endDate: e.target.value }));
  };

  function onSearchChange(value: any) {
    setFilter((prev) => ({
      ...prev,
      consumerNumber: value,
    }));
  }

  const getReportData = async (init: boolean = false) => {
    if (moment(filter.startDate).isAfter(filter.endDate) && !init) {
      Swal.fire({
        icon: "error",
        title: "Date Range Error",
        text: "From Date cannot be greater than To Date",
      });
      return;
    }

    setLoader(true);
    try {
      const response = await getPaymentInquiryDetail(
        init
          ? {
              ...initialFilter,
              startDate: `${initialFilter.startDate} 00:00:00`,
              endDate: `${initialFilter.endDate} 23:59:59`,
            }
          : {
              ...filter,
              startDate: `${filter.startDate} 00:00:00`,
              endDate: `${filter.endDate} 23:59:59`,
              consumerNumber: `${filter.consumerNumber}`,
            }
      );

      if (response.status === 200) {
        const { content } = response.data;
        setInquiryDetailResponses(content[0]?.inquiryDetailResponses || []);
        setPaymentDetailResponses(content[0]?.paymentDetailResponses || []);
      }
    } catch (error) {
      console.error("Error fetching payment inquiry details:", error);
    } finally {
      setLoader(false); // Stop loader
    }
  };

  const splitResponse = (response: any) => {
    const newArray: any = [];
    const listOfItem = response.split("+");
    if (listOfItem.length > 2) {
      newArray.push(listOfItem[0].substring(0, 2));
      newArray.push(listOfItem[0].substring(2, listOfItem[0].length - 9));
      newArray.push(listOfItem[0].substring(listOfItem[0].length - 9, listOfItem[0].length - 8));
      newArray.push(listOfItem[0].substring(listOfItem[0].length - 8));
      newArray.push(`+${listOfItem[1]}`);
      newArray.push(`+${listOfItem[2]}`);
    }
    return newArray;
  };

  function showResponse(response: any) {
    const newArray = splitResponse(response);
    return (
      <div>
        <span
          style={{
            color: newArray[0] === "00" ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {newArray[0]}
        </span>
        <span className="text-info mr-1">{newArray[1]}</span>
        <span style={{ color: newArray[2] === "U" ? "red" : "green" }}>{newArray[2]}</span>
        <span>{newArray[3]}</span>
        <span>{newArray[4]}</span>
        <span>{newArray[5]}</span>
      </div>
    );
  }

  return (
    <div className="dashboard-middle-content">
      {loader || pageData.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
      ) : (
        <>
          <FilterBox _initFilters={_initFilters} getReportData={getReportData}>
            <div className="w-100">
              <ConsumerNumber
                search={filter?.consumerNumber}
                onSearchChange={onSearchChange}
              />
            </div>
            <div className="w-100">
              <input
                className="dashboard-top-filter-input mb-2"
                type="date"
                value={filter?.startDate || ""}
                onChange={onChangeStartDate}
              />
            </div>
            <div className="w-100">
              <input
                className="dashboard-top-filter-input"
                type="date"
                value={filter?.endDate || ""}
                onChange={onChangeEndDate}
              />
            </div>
          </FilterBox>
          <div className="card" style={{ padding: "20px" }}>
            <div>
              <h4>Inquiry</h4>
              <p>Inquiry request and response</p>
              <div className="table-responsive p-2">
                <table className="inquiryPayment">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Consumer Number</th>
                      <th>Bank</th>
                      <th>Created At</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inquiryDetailResponses.length > 0 &&
                      inquiryDetailResponses.map((row: any, index) => (
                        <tr key={row.createdAt}>
                          <td>{index + 1}</td>
                          <td>{row.consumerNumber}</td>
                          <td>{row.bank}</td>
                          <td>{row.createdAt}</td>
                          <td>{showResponse(row.response)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <h4>Payment</h4>
              <p>Payment request and response</p>
              <div className="table-responsive p-2">
                <table className="inquiryPayment">
                  <thead>
                    <tr>
                      <th>Sr #</th>
                      <th>Consumer Number</th>
                      <th>Bank</th>
                      <th>Auth ID</th>
                      <th>Amount</th>
                      <th>Transaction Date</th>
                      <th>Transaction Time</th>
                      <th>Created At</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentDetailResponses.length > 0 &&
                      paymentDetailResponses.map((row: any, index) => (
                        <tr key={row.createdAt}>
                          <td>{index + 1}</td>
                          <td>{row.consumerNumber}</td>
                          <td>{row.bank}</td>
                          <td>{row.authId}</td>
                          <td>{row.transactionAmount}</td>
                          <td>{row.transactionDate}</td>
                          <td>{row.transactionTime}</td>
                          <td>{row.createdAt}</td>
                          <td>
                            <span
                              style={{
                                color:
                                  row.response.toString().trim() === "00"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {row.response}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PaymentInquiry;
