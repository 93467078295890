import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../modules/auth/core/Auth";
import { login } from "../modules/auth/core/_requests";
import clsx from "clsx";
import { UserModel } from "../modules/auth/core/_models";
import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";
import Swal from "sweetalert2";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

const loginSchema = Yup.object().shape({
	email: Yup.string()
	  .min(6, "Minimum 6 characters")
	  .max(50, "Maximum 11 characters")
	  .required("Username is required"),
	password: Yup.string()
	  .min(6, "Minimum 6 characters")
	  .max(50, "Maximum 50 characters")
	  .required("Password is required"),
  });
  
  const initialValues = {
	email: "",
	password: "",
  };

const LoginNew = (props:any)=>{
	const [password, setPassword] = useState("");
	const [type, setType] = useState('password');
	const [icon, setIcon] = useState(eyeOff);

const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const recaptcha = useRef<ReCAPTCHA>(null);

  const [captchaVerified, setCaptchaVerified] = useState(false);

  const onCaptchaChange = (val: any) => {
    setCaptchaVerified(!!val);
  };
  const handleToggle = () => {
	if (type==='password'){
	   setIcon(eye);
	   setType('text')
	} else {
	   setIcon(eyeOff)
	   setType('password')
	}
 }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const captchaValue = recaptcha.current?.getValue();
      if (!captchaValue) {
        Swal.fire({
          icon: "error",
          text: "Please select correct Recaptcha Value",
        });
        setSubmitting(false);
        return;
      }
      setLoading(true);
      try {
        const { data } = await login(values.email, values.password);

        const auth: UserModel = data.content[0];
        // console.log(auth);
        saveAuth(auth);
        // const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(auth);
      } catch (error) {
        console.error(error);
        // saveAuth(undefined)
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });



	const { handleDialog } = props
    return (
	<div>
        <div className="login_wrapper">
			<div>
				<a href="#" onClick={handleDialog} className="popup_close"><i className="fas fa-times"></i></a>
				<div className="login_title">Login</div>
				<form onSubmit={formik.handleSubmit} noValidate id="login_form" className="py-5">
              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                className="col-md-12 field mb-4"
                data-aos="fade-left"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="2000"
              >
                <input
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid": formik.touched.email && formik.errors.email,
                    },
                    { "is-valid": formik.touched.email && !formik.errors.email }
                  )}
                  {...formik.getFieldProps("email")}
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="off"
                />
				<label>Username</label>
                {formik.touched.email && formik.errors.email && (
                  <div className="invalid-feedback">
                    {" "}
                    {formik.errors.email}{" "}
                  </div>
                )}
              </div>
              <div
                className="col-md-12 field mb-4 position-relative"
                data-aos="fade-left"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="2000"
              >
                
                <input
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                  id="password"
                  type={type}
                  {...formik.getFieldProps("password")}
                />
				<span className="flex justify-around items-center position-absolute iconEye" onClick={handleToggle}>
                  <Icon className="absolute mr-10" icon={icon} size={25}/>
              </span>
				<label>Password</label>
                {formik.touched.password && formik.errors.password && (
                  <div className="invalid-feedback">
                    {" "}
                    {formik.errors.password}{" "}
                  </div>
                )}
              </div>
              <div className="clearfix"></div>
              <div className="d-flex justify-content-center">
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE || ""}
                  onChange={onCaptchaChange}
                  theme={"light"}
                  size="normal"
                />
              </div>
              <div className="col-md-12 field mb-3 mt-5">
                <button
                  type="submit"
                  id="sign_in_submit"
                  className="bt btn_login"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !captchaVerified
                  }
                >
                  {!loading && <span className="indicator-label">Login</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            	</form>
				{/* <form action="#" className="py-5">
					<div className="col-md-12 field mb-4">
					<input className="form-control" type="text" required/>
					<label>Email Address</label>
					</div>
					<div className="col-md-12 field mb-4">
					<input className="form-control" type="password" required/>
					<label>Password</label>
					</div>
					<div className="content mb-4">
					<div className="checkbox">
						<input type="checkbox" id="remember-me"/>
						<label>Remember me</label>
					</div>
					<div className="pass-link">
						<a href="#">Forgot password?</a>
					</div>
					</div>
					<div className="col-md-12 field mb-3">
					<button type="submit" className="bt btn_login">Login</button>
					</div>
					
				</form> */}
			</div>
    	</div>
	</div>
	
	)

}

export default LoginNew;