import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import * as Yup from "yup";
import {
  changePasswordUserList,
  getRoles,
  getUsers,
  saveUser,
  updateUser,
  updateUserStatus,
} from "../../../../services/UserService";
import { PasswordMeterComponent } from "../../../../utils/components";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import classes from "./styles.module.css";
import PasswordInput from "../../reusableComponents/PasswordInput";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";

const userCreateScheme = Yup.object().shape({
  username: Yup.string()
    .min(4, "Minimum 4 character")
    .max(11, "Maximum 11 character")
    .required("Username is required"),
  fullName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Fullname is required"),
  cnic: Yup.string().min(13).max(13).required("CNIC Required"),
  mobile: Yup.number().positive().required("Mobile is required"),
  email: Yup.string().email().required("Email is required"),
  roleId: Yup.number().required("Role type is required"),
  password: Yup.string()
    .min(6, "Minimum 6 characters")
    .max(20, "Maximum 20 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});
const userUpdateScheme = Yup.object().shape({
  username: Yup.string()
    .min(4, "Minimum 4 character")
    .max(11, "Maximum 11 character")
    .required("Username is required"),
  fullName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Fullname is required"),
  cnic: Yup.string().min(13).max(13).required("CNIC Required"),
  mobile: Yup.number().positive().required("Mobile is required"),
  email: Yup.string().email().required("Email is required"),
  roleId: Yup.number().required("Role type is required"),
});

const initialFormValues = {
  username: "",
  fullName: "",
  cnic: "",
  mobile: "",
  email: "",
  roleId: "",
};

const initialFormData: any = {
  user: {},
  isUserUpdate: false,
  values: initialFormValues,
};

function Users() {
  const [roles, setRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(initialFormData);
  const updateHeaderContext = useUpdateHeaderContext();
  const [updateUserTitle, setUpdateUserTitle] = useState(false);

  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [selectedUserForPasswordUpdate, setSelectedUserForPasswordUpdate] =
    useState<any>(null);

  const columns = [
    { Header: "Sr#", accessor: "SerialNo" },
    { Header: "Full Name", accessor: "fullName" },
    { Header: "Username", accessor: "username" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "CNIC", accessor: "cnic" },
    { Header: "Email", accessor: "email" },
    { Header: "Role Type", accessor: "role.name" },
    {
      Header: "Status",
      accessor: "active",
      Cell: ({ row }: any) => (
        <>{row.original.active ? "Active" : "Inactive"}</>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: any) => (
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            size="sm"
            className={classes.dropdownToggleAfter}
          >
            <i
              className="bi bi-three-dots-vertical icon-red"
              style={{ color: "green" }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => updateValues(row.original)}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                await updateStatus(row.original.id, !row.original.active);
              }}
            >
              {row.original.active ? "Deactivate" : "Activate"}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setSelectedUserForPasswordUpdate(row.original);
                setIsPasswordModalVisible(true);
              }}
            >
              Update Password
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    getUserList();
    getRoleList();
  }, []);

  const getUserList = async () => {
    const response = await getUsers();
    setPageData((prevState: any) => ({
      ...prevState,
      isLoading: false,
      rowData: response.data.content,
      totalPages: 10,
      totalRows: response.data.content.length,
    }));
  };

  const getRoleList = async () => {
    const response = await getRoles();
    setRoles(response.data.content);
  };

  const handleSubmit = async (data: any) => {
    setLoading(true);
    let userId = 0;
    try {
      if (formData.isUserUpdate) {
        userId = formData.user.id;
      }
      let postdata: any = {
        id: userId,
        fullName: data.fullName,
        cnic: data.cnic,
        mobile: data.mobile,
        roleId: data.roleId,
      };

      if (formData.isUserUpdate) {
        postdata.username = formData.user.username;
        await updateUser(postdata);
      } else {
        postdata.password = data.password;
        postdata.username = data.username;
        postdata.email = data.email;
        await saveUser(postdata);
      }
      setLoading(false);
      getUserList();
      cancelRequest();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const updateStatus = async (id: any, status: any) => {
    const postData = {
      id: id,
      activated: status,
    };
    updateUserStatus(postData).then(() => {
      getUserList();
    });
  };

  const updateValues = (row: any) => {
    const _formData = formData;
    _formData.user = row;
    _formData.isUserUpdate = true;
    _formData.values = {
      username: row.username,
      fullName: row.fullName,
      cnic: row.cnic,
      mobile: row.mobile,
      email: row.email,
      roleId: row.role.id,
    };
    setUpdateUserTitle(true);
    setFormData(_formData);
    setTimeout(() => setShowForm(true), 500);
  };

  const addNewUser = () => {
    setFormData(initialFormData);
    setTimeout(() => setShowForm(true), 500);
    setUpdateUserTitle(false);
  };

  const cancelRequest = () => {
    setFormData(initialFormData);
    setShowForm(false);
    setUpdateUserTitle(false);
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
  };

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
  };

  const onInput = (e: any, digits: number) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, digits);
  };

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Users" });
  }, []);
  return (
    <div className="dashboard-middle-content">
      <div className={classes.addUserButtonRow}>
        <div />
        <div className="">
          <div className="white-bg">
            <div className="float-right">
              <button
                className={`mb-3 ${classes.addRoleButton}`}
                onClick={addNewUser}
              >
                Add Users
              </button>
            </div>
          </div>
        </div>
      </div>
      {pageData?.rowData && (
        <>
          <AppTable
            columns={columns}
            data={pageData.rowData.map((row:any , index:any) =>({
              ...row,
              SerialNo: (
                <div>
                  {index + 1}
                </div>
              ),
            })
            ).slice((currentPage - 1) * pageSize,
            currentPage * pageSize)}
            isLoading={loading}
          />
          <Pagination
            totalRows={pageData.totalRows}
            pageChangeHandler={setPageIndex}
            rowsPerPage={pageSize}
            currentPage={currentPage}
            pageItemChangeHandler={onLoadPageItems}
          />
        </>
      )}
      {isPasswordModalVisible && (
        <Modal
          size="lg"
          style={{ width: "500px", margin: "auto" }}
          show={isPasswordModalVisible}
          onHide={() => setIsPasswordModalVisible(false)}
        >
          <div className="modal-content p-2">
            <span
              className="close"
              style={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={() => setIsPasswordModalVisible(false)}
            >
              &times;
            </span>
            <Formik
              initialValues={{ newPassword: "", confirmNewPassword: "" }}
              validationSchema={Yup.object({
                newPassword: Yup.string()
                  .min(8, "Password must be at least 8 characters")
                  .required("New password is required"),
                confirmNewPassword: Yup.string()
                  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                  .required("Confirming new password is required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                changePasswordUserList({
                  id: selectedUserForPasswordUpdate.id,
                  password: values.newPassword,
                })
                  .then(() => {
                    setIsPasswordModalVisible(false);
                    Swal.fire({
                      icon: "success",
                      title: "Password Updated",
                      text: "The password has been successfully updated.",
                    });
                    setIsPasswordModalVisible(false);
                  })
                  .catch(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ errors, touched }) => (
                <div style={{ backgroundColor: "white", padding: "30px" }}>
                <h3 className="text-center mb-5" style={{ fontWeight:'600' }}>Update Password</h3>
                  <Form>
                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <PasswordInput
                        name="newPassword"
                        type="password"
                        className={`form-control ${
                          errors.newPassword && touched.newPassword
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmNewPassword">
                        Confirm New Password
                      </label>
                      <PasswordInput
                        name="confirmNewPassword"
                        type="password"
                        className={`form-control ${
                          errors.confirmNewPassword && touched.confirmNewPassword
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="confirmNewPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mt-3 d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">
                        Update Password
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </Modal>
      )}
      {showForm && (
        <Modal
          size="lg"
          style={{ width: "800px", margin: "auto" }}
          show={showForm}
          onHide={() => setShowForm(false)}
        >
          <div className="modal-content p-2">
            <div style={{ backgroundColor: "white", padding: "30px" }}>
              <h3 className="text-center mb-5" style={{ fontWeight:'600' }}>{updateUserTitle ? "Update User": "Add User"}</h3>
              <Formik
                initialValues={formData.values}
                validationSchema={
                  formData.isUserUpdate ? userUpdateScheme : userCreateScheme
                }
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, touched, resetForm }) => (
                  <Form>
                    <div className="row">
                    <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">Role</label>
                        </div>
                        <Field
                          className={
                            "form-control" +
                            (errors.roleId && touched.roleId
                              ? " is-invalid"
                              : "")
                          }
                          as="select"
                          id="roleId"
                          name="roleId"
                        >
                          <option value="">Select Role</option>
                          {roles.map(
                            (role: any) =>
                              role.roleType && (
                                <option key={role.id} value={role.id}>
                                  {role.roleType}
                                </option>
                              )
                          )}
                        </Field>
                        <ErrorMessage
                          name="roleId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">Username</label>
                        </div>
                        <Field
                          className={
                            "form-control w-100" +
                            (errors.username && touched.username
                              ? " is-invalid"
                              : "")
                          }
                          type="text"
                          id="username"
                          name="username"
                          disabled={formData.isRoleUpdate}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">Email</label>
                        </div>
                        <Field
                          className={
                            "form-control w-100" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                          type="text"
                          id="email"
                          name="email"
                          disabled={formData.isRoleUpdate}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">Full Name</label>
                        </div>
                        <Field
                          className={
                            "form-control w-100" +
                            (errors.fullName && touched.fullName
                              ? " is-invalid"
                              : "")
                          }
                          type="text"
                          id="fullName"
                          name="fullName"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">CNIC</label>
                        </div>
                        <Field
                          className={
                            "form-control w-100" +
                            (errors.cnic && touched.cnic ? " is-invalid" : "")
                          }
                          type="text"
                          maxLength={13}
                          onInput={(e: any) => onInput(e, 13)}
                          id="cnic"
                          name="cnic"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="cnic"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <label className="labelName">Mobile</label>
                        </div>
                        <Field
                          className={
                            "form-control w-100" +
                            (errors.mobile && touched.mobile
                              ? " is-invalid"
                              : "")
                          }
                          type="text"
                          id="mobile"
                          name="mobile"
                          autoComplete="off"
                          maxLength={11}
                          onInput={(e: any) => onInput(e, 11)}
                        />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      
                      {!formData.isUserUpdate && (
                        <>
                          <div className="col-md-6 mb-3">
                            <div>
                              <label className="labelName">Password</label>
                            </div>
                            <PasswordInput
                              className={
                                "form-control w-100" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              type="password"
                              id="password"
                              name="password"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                            {/* begin::Meter */}
                            <div
                              className="d-flex align-items-center mb-3"
                              data-kt-password-meter-control="highlight"
                            >
                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                            </div>
                            {/* end::Meter */}
                            <div className="text-muted">
                              Use 8 or more characters with a mix of letters,
                              numbers & symbols.
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div>
                              <label className="labelName">
                                Confirm Password
                              </label>
                            </div>
                            <PasswordInput
                              className={
                                "form-control w-100" +
                                (errors.confirmPassword &&
                                touched.confirmPassword
                                  ? " is-invalid"
                                  : "")
                              }
                              type="password"
                              id="confirmPassword"
                              name="confirmPassword"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </>
                      )}
                      <div className="clearfix"></div>
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="float-right mt-4">
                          <button
                            className={`${classes.cancelButton} mr-4`}
                            type="button"
                            onClick={cancelRequest}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            id="sign_in_submit"
                            className={`${classes.saveButton}`}
                          >
                            {!loading && (
                              <span className="indicator-label">
                                {formData.isUserUpdate ? "Update" : "Save"}
                              </span>
                            )}
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Users;
