export const endPoints = {
  INTIMATION_FAILURE_REPORT_URL: `/reporting/intimationFailureReport`,
  INTIMATION_TRIES_DETAIL_URL: `/reporting/intimationTriesDetail`,
  RESET_INTIMATIONS_URL: `/reporting/resetIntimations`,
  ADD_SERVICE_URL: `/addCompanyService`,
  COMPANY_TRANSACTIONS_SUMMARY_URL: `/reporting/companyTransactionsSummary`,
  LINK_CONFIRMATION_URL: `/confirmation`,
  PSID_STATUS_DETAILS_URL: `/reporting/psidStatus`,
  REJECTED_PAYMENT_CONFIRMATION_REPORT_URL: `/reporting/rejectedPaymentConfirmationReport`,
  REVERT_PAYMENT_URL: `/reporting/revertPayment`,
  REVERTED_PAYMENT_LIST_URL: `/reporting/revertedPaymentList`,
  PAYMENT_INTIMATION_CONFIGURATION_URL: '/reporting/paymentIntimationConfiguration',
  PAYMENT_CONFIGURATION_URL: '/reporting/paymentConfiguration',
  PAYMENT_INQUIRY_DETAIL_URL: '/reporting/inquiryAndPaymentDetail'
};
