import iconPath from "../../../../assets/images/serviceImage.png";
import classes from "./styles.module.css"
interface DashboardServiceBoxProps {
  title: string;
  amountCollected: string;
  nOfTransactions: string;
}

const DashboardServiceBox = ({
  title,
  amountCollected,
  nOfTransactions,
}: DashboardServiceBoxProps) => {
  return (
    <div
      className={classes.dashboardServiceBox1}
    >
      <div
        className={classes.dashboardServiceBox3}
      >
        <img
          src={iconPath}
          alt="dashboard-icon"
          className="img-responsive"
          width={35}
          height={35}
        />
        <div style={{ fontWeight: 700, fontSize: "18px", whiteSpace:"nowrap", overflow:'hidden', textOverflow:'ellipsis' }} className="mt-2" title={title}>
          {title}
        </div>
      </div>
      <div className={classes.dashboardServiceBox2}>
        <div style={{ fontWeight: 500, fontSize: "14px", color: '#00000080' }} className="mt-4">
          Amount Collected
        </div>
        <div style={{ fontWeight: 600, fontSize: "24px" }}>
          {amountCollected}
        </div>
      </div>
      <div
        style={{display: "flex", justifyContent: "space-between", alignItems: "center",}} className="mt-2">
        <div style={{ fontWeight: 500, fontSize: "14px", color: '#00000080' }}>
          No of Transactions
        </div>
        <div style={{ fontWeight: 600, fontSize: "24px" }}>
          {nOfTransactions}
        </div>
      </div>
      
    </div>
  );
};

export default DashboardServiceBox;
