import { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import FilterIcon from "../../../../assets/svgs/filterIcon.svg";
import styles from "./styles.module.css";
import cross_icon from "./../../../../assets/images/filter_icons/cross_icon.png";
import excelicon from "./../../../../assets/images/filter_icons/Excel.png";

const FilterBox = ({
  _initFilters,
  getReportData,
  children,
  exportExcel,
}: any) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const filterBoxRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null); 
 const handleMouseEnter = () => {
    if (window.innerWidth > 768) {
      setShowFilterBox(true);
    }
  };

  const handleMouseLeave = (event: React.MouseEvent) => {
    if (window.innerWidth > 768) {
      if (
        !filterBoxRef.current?.contains(event.relatedTarget as Node) &&
        !buttonRef.current?.contains(event.relatedTarget as Node)
      ) {
        setShowFilterBox(false);
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterBoxRef.current &&
      event.target instanceof Node &&
      !filterBoxRef.current?.contains(event.target) &&
      !buttonRef.current?.contains(event.target)
    ) {
      setShowFilterBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onFilterBoxClick = () => {
    getReportData();
    setShowFilterBox(false);
  };

  const onResetBoxClick = () => {
    _initFilters();
    setShowFilterBox(false);
  };

  const toggleFilterBoxClick = () => {
    if (window.innerWidth <= 768) {
      setShowFilterBox(!showFilterBox);
    }
  };
  return (
    <>
      {showFilterBox && <div className={styles.overlay}></div>}
      <div className="clearfix">
        <div className="float-left mt-2"></div>

        <div
          className="float-right"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={buttonRef} 
        >
          <Button
            className="heightBtn"
            color="white"
            onClick={toggleFilterBoxClick} 
          >
            <img src={FilterIcon} alt="Filter" style={{ marginRight: "10px" }} />
            <span className={styles.filterButtonText}>Filter</span>
          </Button>
        </div>
      </div>
      <div
        className={
          showFilterBox ? styles.filterBoxVisible : styles.filterBoxHidden
        }
        ref={filterBoxRef}
        onMouseLeave={handleMouseLeave} 
      >
        <div
          style={{
            backgroundColor: "#738AD60A",
            padding: "20px",
            width: "100%",
            display: "inline-block",
          }}
          className="text-center position-relative"
        >
          <img
            src={cross_icon}
            style={{
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
            className="position-absolute"
            onClick={() => setShowFilterBox(false)}
          />
        </div>
        <div style={{ padding: "20px" }}>
          <div style={{display:"flex", flexDirection:"column", gap: "10px"}}>{children}</div>
          <div className="w-100 mt-4">
            {_initFilters && (
              <button
                className="diableResetButton"
                onClick={onResetBoxClick}
                style={{ width: "38%" }}
              >
                Reset
              </button>
            )}
            <span style={{ width: "2%" }}> </span>
            <button
              className="filterButton"
              onClick={onFilterBoxClick}
              style={{ width: "60%" }}
            >
              Apply Filter
            </button>
          </div>
          {exportExcel && (
            <div className="w-100 mt-2">
              <button
                className="diableResetButton"
                onClick={exportExcel}
                style={{
                  width: "100%",
                  border: "1px solid #21A366",
                  padding: "5px 0",
                  marginTop: "5px",
                }}
              >
                <img src={excelicon} style={{ cursor: "pointer" }} />
                Download Excel
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mb-2" />
    </>
  );
};
export default FilterBox;
